import { useCallback, useEffect, useState } from "react";
import { loadArticle } from "../../service/api";
import {
  Link,
} from "react-router-dom";

const RawBookDetail = ({
  bookId,
  index,
}) => {
  const [article, setArticle] = useState(null);
  const [indexText, setIndexText] = useState('001');
  const [nextIndexText, setNextIndexText] = useState('002');
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    const text = `${index}`
    const nextText = `${+index + 1}`
    setIndexText(text.padStart(3, '0'));
    setNextIndexText(nextText.padStart(3, '0'));

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }, [index]);
  
  useEffect(() => {
    loadArticle(bookId, indexText).then(res => {
      setArticle(res);
    });
    loadArticle(bookId, nextIndexText).then(() => {
      setHasNext(true);
    }).catch(e => {
      setHasNext(false);
    });
    
  }, [indexText]);

  const renderPage = useCallback(() => {
    if (!article) { return null}
    const { title, content } = article;
    const sections = content.split('<br/>').map((item) => {
      return `<p>${item}</p>`;
    }).join('');
    const titleHTML = title.split('　').map((item) => {
      return `<p>${item}</p>`;
    }).join('');
    const contentJSX = (
      <>
        <h1 dangerouslySetInnerHTML={{ __html: titleHTML }}></h1>
        <div dangerouslySetInnerHTML={{ __html: sections }}></div>
      </>
    )
    return <div className="wrapper">
      {contentJSX}
    </div>
  }, [article]);

  return (
    <div className="book-detail">
      {renderPage()}
      <div className="center">
        {
          hasNext ? <Link className="link-btn" to={`/detail/${bookId}/${+index + 1}`}>下一章</Link>
            : null
        }
        <Link className="link-btn mute" to={`/`}>首页</Link>
      </div>
    </div>
  );
};

export default RawBookDetail;