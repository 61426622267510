import {
  createBrowserRouter,
} from "react-router-dom";

import Home from '../pages/Home';
import Detail from '../pages/Detail';

export const routers = createBrowserRouter([
  {
    path: "/",
    element: (
      <Home></Home>
    ),
  },
  {
    path: "/detail/:bookId/:index",
    element: <Detail></Detail>,
  },
]);