export const request = function (method, url, data = undefined, config = {}) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      ...config,
    }).then((resp) => {
      return resp.json();
    }).then((data) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    });
  });
}
