import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
} from "react-router-dom";
import { routers } from './routers';

// import BookDetail from './components/BookDetail';
// import RawBookDetail from './components/RawBookDetail';
import './common.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="book">
    <RouterProvider router={routers} />
  </div>
);
