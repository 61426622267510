import BookLink from "./BookLink";
import books from "./books";

function Home () {
  return <div>
    {
      books.map(item => {
        return <BookLink key={item.id} {...item}></BookLink>
      })
    }
  </div>
}

export default Home;
