import {
  Link,
} from "react-router-dom";

function BookLink ({
  id,
  text,
}) {
  return (
    <div className="book-link">
      <Link to={`detail/${id}/1`}>
        <div className="book-img"><span className="name">{text}</span></div>
        <div className="book-name">开始阅读</div>  
      </Link>
    </div>
  )
}

export default BookLink;