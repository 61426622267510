const books = [{
  id: 'sanguoyanyi',
  text: '三国演义',
}, {
  id: 'hongloumeng',
  text: '红楼梦',
}, {
  id: 'shuihuzhuan',
  text: '水浒传',
}, {
  id: 'xiyouji',
  text: '西游记',
}, {
  id: 'soushenji',
  text: '搜神记',
}, {
  id: 'sunzibingfa',
  text: '孙子兵法',
}, {
  id: 'guiguzi',
  text: '鬼谷子',
}];

export default books;
