import { useParams } from "react-router-dom";
import RawBookDetail from '../../components/RawBookDetail';

function Detail () {
  console.log('useParams', useParams())
  return <RawBookDetail
    bookId={useParams().bookId}
    index={useParams().index}
  />
}

export default Detail;
